import styled from 'styled-components'

export const H1 = styled.h1`
  font-size: clamp(1.75rem, calc(1rem + 3vw), 6rem);
  color: ${({theme}) => theme.contrast};
  font-weight: 800;
`
export const H2 = styled(H1).attrs(props => ({
  as: "h2"
}))`
  font-size: clamp(1.25rem, calc(1rem + 2.5vw), 3rem);
`
export const H3 = styled(H1).attrs(props => ({
  as: "h3"
}))`
  font-size: clamp(1rem, calc(1rem + 2.25vw), 2rem);
`
export const H4 = styled(H1).attrs(props => ({
  as: "h4"
}))`
  font-size: clamp(.75rem, calc(1rem + 1.5vw), 1.5rem);
`
export const P = styled.p`
  font-size: clamp(.5rem, calc(1rem + .25vw), 1.5rem);
`