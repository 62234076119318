import React from 'react'
import styled from 'styled-components'
import { PrimaryButton } from '../common/button'
import Image from './image'
import { H1, P } from '../../components/page-elements/text'

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 5vh;

  @media (min-width: 1024px) {
    grid-template-columns: 50% 50%;
    max-width: 80%;
  }
`
const Content = styled.div`
  margin: 0 auto;
  padding: 1rem;
  @media (min-width: 768px) {
    width: 80%;
  }
`
const Heading = styled(H1)`
  margin-bottom: 1rem;
`
const Paragraph = styled(P)`
  line-height: 1.2em;
  margin-bottom: 1rem;
`
const Button = styled(PrimaryButton)`
  margin-right: 1rem;
  margin-bottom: 1rem;
`
const Hero = ({ toggleForm }) => {
  return (
    <Container>
      <Image />
      <Content>
        <Heading>A new foe has appeared!</Heading>
        <Paragraph>
          Follow Skyler on his quest to invent phrases the world has never heard
          before. Featuring the most exotic hot things, the rarest beers, and
          the occasional appearance by his patsy, Trevor.
        </Paragraph>
        <Paragraph>
          Skyler is currently accepting your hottest challenges.
        </Paragraph>
        <Button onClick={(e) => toggleForm(e)}>CHALLENGE HIM! →</Button>
      </Content>
    </Container>
  )
}

export default Hero
