import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import Video from './video'
import Link from './common/link'
import { H2 } from './common/text'

const Container = styled.div`
  display: grid;
  margin-top: 4rem;
  margin-bottom: 4rem;
`
const Background = styled.div`
  grid-area: 1/1;
  background: ${({ theme }) => theme.brand};
  transform: skewY(3deg);
  transform-origin: top left;
`
const Content = styled.div`
  grid-area: 1/1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;

  > * {
    margin-bottom: 1rem;
  }
  > *:not(:last-child) {
    flex-basis: 100%;
  }
  > :last-child {
    margin: 0 auto;
  }
  @media (min-width: 1024px) {
    width: 1024px;
    margin: auto;
  }
`
const StyledLink = styled(Link)`
  display: inline-block;
  border: solid ${({ theme }) => theme.contrast};
  padding: 0.75rem;
  color: ${({ theme }) => theme.primary};
  background: ${({ theme }) => theme.contrast};
`

const FeaturedVideo = () => {
  const [videoData, setVideoData] = useState({})
  const [loading, setLoading] = useState(true)

  // fetch latest video on component mount
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios('/api/latest-video')
      setVideoData({ ...data.items[0] })
      setLoading(false)
    }
    fetchData()
  }, [])

  return (
    <Container>
      <Background />
      <Content>
        <H2>Latest Video</H2>
        {!loading ? (
          <Video
            videoId={videoData.contentDetails.videoId}
            title={videoData.snippet.title}
          />
        ) : null}
        <StyledLink to={'/videos/'}>More Videos &rarr;</StyledLink>
      </Content>
    </Container>
  )
}

export default FeaturedVideo
