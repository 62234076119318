import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { H1 } from '../page-elements/text'

const Container = styled.div`
  display: grid;
  margin: 0 auto;
  max-width: 768px;
`
const StyledImage = styled(GatsbyImage)`
  grid-area: 1 / 1;
`
const Overlay = styled.div`
  grid-area: 1 / 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.4) 20%, rgba(0,0,0,0) 40%, rgba(0,0,0,0) 70%, rgba(0,0,0,0) 100%);
`
// Text styled "as=p" to remain semantic but inherit the h1 styles
const Text = styled(H1)`
  position: relative;
  color: ${({theme}) => theme.accent};
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: -0.055em;
  line-height: .75em;
  z-index: 2;
`

const Image = () => {
  const data = useStaticQuery(graphql`
    query ImageQuery {
      file(relativePath: {eq: "skyler-one.jpg"}) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)

  return (
    <Container>
      <StyledImage 
        image={data.file.childImageSharp.gatsbyImageData}
        alt={"Our hero Skyler saluting the troops."}
      />
      <Overlay>
        <Text as="p">Always Average<br /> Never Mediocre</Text>
      </Overlay>
    </Container>
  )
}

export default Image