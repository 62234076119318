import * as React from 'react'
import Hero from '../components/hero'
import FeaturedVideo from '../components/featured-video'
import Seo from '../components/seo'
import styled from 'styled-components'
import ChallengeForm from '../components/challenge-form'

const Wrapper = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`
const IndexPage = () => {
  const [showForm, setShowForm] = React.useState(false)

  const toggleForm = (e) => {
    e.preventDefault()
    setShowForm(!showForm)
  }

  return (
    <>
      <ChallengeForm showForm={showForm} toggleForm={toggleForm} />
      <Seo title='Home' />
      <Wrapper>
        <Hero toggleForm={toggleForm} />
        <FeaturedVideo />
      </Wrapper>
    </>
  )
}

export default IndexPage
