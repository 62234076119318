import React, { useState } from 'react'
import styled from 'styled-components'
import { TextInput } from '../common/text-input'
import { TextArea } from '../common/text-area'
import { PrimaryButton } from '../common/button'
import CloseButton from './close-button'

const Form = styled.form`
  position: relative;
  color: ${({ theme }) => theme.contrast};
  padding: 2rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  margin: 0 auto;
  background: ${({ theme }) => theme.primary};
  width: min(100%, 500px);
  z-index: 10;
`
const Modal = styled.div`
  position: fixed;
  display: ${({ showForm }) => (showForm ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
`
const Header = styled.header`
  display: flex;
  margin-bottom: 2rem;
`
const Heading = styled.h2`
  font-size: 1.5rem;
  font-weight: 900;
  text-transform: uppercase;
  flex: 1;
  > span {
    color: ${({ theme }) => theme.accent};
  }
`
const Inner = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 0.8rem;
  font-weight: 500;
  color: ${({ theme }) => theme.contrast};

  > * {
    flex: 1 0 100%;
  }
`
const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const ChallengeForm = ({ toggleForm, showForm }) => {
  const [values, setValues] = useState({
    name: '',
    email: '',
    message: '',
  })

  const handleChange = ({ target }) => {
    setValues((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }))
  }

  const handleSubmit = (e) => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'challenger', ...values }),
    })
      .then(() => alert('Success!'))
      .catch((error) => alert(error))

    e.preventDefault()
  }

  return (
    <Modal showForm={showForm}>
      <Form
        onSubmit={handleSubmit}
        name='challenger'
        data-netlify='true'
        data-netlify-honeypot='bot-field'>
        <Header>
          <Heading>
            Submit <span>spicy</span> challenges
          </Heading>
          <CloseButton handleClick={toggleForm}>X</CloseButton>
        </Header>
        <input type='hidden' name='form-name' value='challenger' />
        <Inner>
          <TextInput
            type='text'
            label='Name'
            name='name'
            value={values.name}
            onChange={(e) => handleChange(e)}
          />
          <TextInput
            type='email'
            label='Email'
            name='email'
            value={values.email}
            onChange={(e) => handleChange(e)}
          />
          <TextArea
            label='Your Challenge'
            name='message'
            rows='4'
            value={values.message}
            onChange={(e) => handleChange(e)}
          />
          <PrimaryButton type='submit'>Submit</PrimaryButton>
        </Inner>
      </Form>
    </Modal>
  )
}

export default ChallengeForm
