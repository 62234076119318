import React from 'react'
import styled from 'styled-components'
import Button from '../common/button'

const Close = styled(Button)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
  margin: 0;
  padding: 0;
  border: none;
  background: transparent;
  height: 15px;
  width: 15px;
  color: #86b974;
  cursor: pointer;
  align-self: flex-start;
  &:focus {
    outline: none;
  }
  > div {
    width: 15px;
    height: 2px;
    transform-origin: 2px;
    background: ${({ theme }) => theme.contrast};
    :first-child {
      transform: rotate(45deg);
    }
    :last-child {
      transform: rotate(-45deg);
    }
  }
`

const CloseButton = ({ handleClick }) => {
  return (
    <Close onClick={(e) => handleClick(e)}>
      <div />
      <div />
    </Close>
  )
}

export default CloseButton
