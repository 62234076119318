import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const TextAreaTag = ({ className, label, rest }) => (
  <label
    className={className}
    label={label}
  >
    {label}
    <textarea {...rest} />
  </label>
)

export const TextArea = styled(TextAreaTag)`
  display: block;
  margin-right: 1em;
  margin-bottom: 1em;
  >textarea {
    display: block;
    margin-top: .5em;
    padding: .5em;
    border: none;    
    outline: none;
    width: 100%;
  }
`

TextArea.propTypes = {
  label: PropTypes.string,
}

TextArea.defaultProps = {
  label: '',
}

export default TextArea