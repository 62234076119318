import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Button = ({ type, onClick, className, children  }) => (
  <button type={type} onClick={onClick} className={className}>
    {children}
  </button>
)

Button.propTypes = {
  type: PropTypes.string,
}
Button.defaultProps = {
  type: 'button',
}

export default Button

export const PrimaryButton = styled(Button)`
  display: inline-block;
  background: ${({theme}) => theme.brand};
  border: 2px solid ${({theme}) => theme.brand};
  color: ${({theme}) => theme.light};
  font-weight: 700;
  padding: 0.5em 1em;
  cursor: pointer;
  @media(hover: hover) {
    &:hover {
      filter: saturate(200%);
    }
  }
  &:active {
    filter: saturate(50%);
  }
`