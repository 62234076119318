import React from 'react'
import styled from 'styled-components'

const VideoBox = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
`
const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
const Title = styled.span`
  display: ${({ showTitle }) => (showTitle ? 'inline' : 'none')};
`

const Video = ({ title, videoId, showTitle, className }) => {
  return (
    <div style={{ width: '100%', maxWidth: '768px' }}>
      <VideoBox>
        <Iframe
          src={`https://www.youtube.com/embed/${videoId}`}
          title={title}
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
          className={className}></Iframe>
      </VideoBox>
      <Title showTitle={showTitle}>{title}</Title>
    </div>
  )
}

export default Video
