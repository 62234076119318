import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Input = ({ className, label, type, name, rest }) => (
  <label
    className={className}
    label={label}
  >
    {label}    
    <input
      type={type}
      name={name}
      {...rest}
    />
  </label>
)

export const TextInput = styled(Input)`
  margin-right: 1em;
  margin-bottom: 1em;
  display: block;
  >input {
    display: block;
    margin-top: .5em;
    padding: .5em;
    border: none;
    outline: none;
    width: 100%;
  }
`



TextInput.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  
}

TextInput.defaultProps = {
  type: '',
  label: '',

}

export default Input